@import "include.less";

#error {
	background-color: @off-white-bg;
	.js-required,
	.logged-out {
		display: none;
	}
	text-align: center;
	min-height: 400px;

	.error-graphic {
		img {
			width: 400px;
			height: 300px;
		}
	}
	h1 {
		font-size: 42px;
		line-height: 50px;
		color: @grey-dk;
		margin: 0 0 30px 0;
		&:after {
			content: "";
			background: @off-white-dk;
			width: 100px;
			height: 4px;
			display: block;
			margin: 0 auto;
			position: relative;
			bottom: -12px;
		}
	}
	h2 {
		font-size: 24px;
		line-height: 34px;
		font-weight: normal;
		margin: 0 0 16px 0;
	}
	h3 {
		font-size: @big;
		line-height: 30px;
	}
	p {
		font-size: @big;
		line-height: 24px;
		margin: 0 0 12px 0;
		a:not(.button) {
			color: @blue;
			&:hover {
				text-decoration: underline;
				color: @blue-dk;
			}
		}
	}
	&:not([data-logged-in]) {
		padding-top: 0 !important;
		p { font-size: 22px; }
		.logged-out { display: block; }
	}
}
